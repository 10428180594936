<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="flex vx-row no-gutter justify-center items-stretch">
                        <div class="vx-col lg:block lg:w-1/2 bg-contain" 
                            id="login-logo" />
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Вход</h4>
                                </div>
                                <vs-input
                                    @keyup.enter="tryLogin()"
                                    v-validate="'required'"
                                    data-vv-validate-on="blur"
                                    name="login"
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label-placeholder="Логин"
                                    v-model="login"
                                    class="w-full no-icon-border"/>
                                <span class="text-danger text-sm">{{ errors.first('login') }}</span>

                                <vs-input
                                    @keyup.enter="tryLogin()"
                                    data-vv-validate-on="blur"
                                    v-validate="'required'"
                                    type="password"
                                    name="password"
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="Пароль"
                                    v-model="password"
                                    class="w-full mt-6 no-icon-border" />
                                <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                                <vs-divider/>
                                <vs-button :disabled="!validateForm" @click="tryLogin">Войти</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            login: '',
            password: '',
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.login != '' && this.password != '';
        },
    },
    methods: {
        tryLogin() {
            this.$vs.loading();

            const payload = {
                checkbox_remember_me: this.checkbox_remember_me,
                userDetails: {
                    login: this.login,
                    password: this.password
                },
                notify: this.$vs.notify,
                closeAnimation: this.$vs.loading.close
            }
            this.$store.dispatch('auth/loginAttempt', payload);
        }
    }
}
</script>

<style lang="scss">
#login-logo {
    background-image: url('../../assets/images/pages/logo-k7.svg');
    background-repeat: no-repeat;
    background-position: center;
}
</style>